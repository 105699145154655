exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-dinner-en-jsx": () => import("./../../../src/pages/dinner.en.jsx" /* webpackChunkName: "component---src-pages-dinner-en-jsx" */),
  "component---src-pages-dinner-no-jsx": () => import("./../../../src/pages/dinner.no.jsx" /* webpackChunkName: "component---src-pages-dinner-no-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-no-jsx": () => import("./../../../src/pages/index.no.jsx" /* webpackChunkName: "component---src-pages-index-no-jsx" */),
  "component---src-pages-lunch-en-jsx": () => import("./../../../src/pages/lunch.en.jsx" /* webpackChunkName: "component---src-pages-lunch-en-jsx" */),
  "component---src-pages-lunch-no-jsx": () => import("./../../../src/pages/lunch.no.jsx" /* webpackChunkName: "component---src-pages-lunch-no-jsx" */),
  "component---src-pages-saturday-lunch-en-jsx": () => import("./../../../src/pages/saturday-lunch.en.jsx" /* webpackChunkName: "component---src-pages-saturday-lunch-en-jsx" */),
  "component---src-pages-saturday-lunch-no-jsx": () => import("./../../../src/pages/saturday-lunch.no.jsx" /* webpackChunkName: "component---src-pages-saturday-lunch-no-jsx" */),
  "component---src-pages-sunday-en-jsx": () => import("./../../../src/pages/sunday.en.jsx" /* webpackChunkName: "component---src-pages-sunday-en-jsx" */),
  "component---src-pages-sunday-no-jsx": () => import("./../../../src/pages/sunday.no.jsx" /* webpackChunkName: "component---src-pages-sunday-no-jsx" */)
}

